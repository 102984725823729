<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-2">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="MILL" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="departmentModel" :items="departmentLists" default="" item-value="dept_id" item-text="dept_name" label="DEPARTMENT" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Start Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" label="End Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="approvedModel" :items="approvedLists" default="" item-value="value" item-text="descr" label="APPROVED" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="statModel" :items="statLists" default="" item-value="value" item-text="descr" label="STATUS" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-12 text-right">
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>                            
                    </div>
                </div>

            </div>

            <div class="col-lg-12 cardResult">
                <div class="card elevation-5">
                    <div class="card-body">
                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                            <template v-slot:[`item.status_aprv`]="{ item }">
                                <v-btn text color="red" v-if="item.dt_approve2 === '1900-01-01 00:00:00.000'">Not Approved</v-btn>
                                <v-btn text color="success" v-else>{{ (new Date(new Date(item.dt_approve2) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}</v-btn>
                            </template> 
                            <template v-slot:[`item.showDetails`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip @click="showItemDetails(item)" color="blue" link small v-on="on" dark>Details</v-chip>
                                    </template>
                                    <span>Show Detail</span>
                                </v-tooltip>
                            </template>

                            <template v-slot:[`item.showDetails`]="{ item }">
                                <v-menu bottom right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on="on" color="grey" class="text-center"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item @click="showItemDetails(item)">
                                            <v-list-item-icon>
                                                <v-icon>mdi-eye</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Detail</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-if="item.dt_approve2 === '1900-01-01 00:00:00.000' && approveUser" @click="acceptApproval(item)">
                                            <v-list-item-icon>
                                                <v-icon>mdi-check</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Approve</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-if="item.dt_approve2 != '1900-01-01 00:00:00.000' && approveUser" @click="rejectApproval(item)">
                                            <v-list-item-icon>
                                                <v-icon>mdi-close</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>UnApprove</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template> 
                        </v-data-table>
                    </div>
                </div>
            </div>

            <v-dialog v-model="dialogDetail" max-width="80%">
                <div class="col-lg-12 cardResult">
                    <div class="card elevation-5">
                        <div class="card-body">
                            <v-data-table :headers="detailHeaders" :items="detailItemLists" class="elevation-1" page-count="5" :loading="detaillLoadingDatatable" dense>
                            </v-data-table>
                        </div>
                    </div>
                </div>
            </v-dialog>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'GBRK',
                disabled: false,
                href: '/admin/gbrk',
                },
                {
                text: 'Purchasing',
                disabled: false,
                href: '#',
                },
                {
                text: 'Purchase Request',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            departmentModel: '',
            departmentLists: [],
            dateStartModal: false,
            dateStartModel: '',
            dateStartFormatted: '',
            dateEndModal: false,
            dateEndModel: '',
            dateEndFormatted: '',
            approvedModel: '',
            approvedLists: [
                {
                    value: '',
                    descr: 'ALL'
                },
                {
                    value: 'N',
                    descr: 'NOT APPROVED'
                }
            ],
            statModel: '',
            statLists: [
                {
                    value: '',
                    descr: 'ALL'
                },
                {
                    value: 'O',
                    descr: 'OPEN'
                },
                {
                    value: 'C',
                    descr: 'CLOSED'
                }
            ],
            headers: [
                { text: '', value: 'status_aprv', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'MILL', value: 'mill_name', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DEPT.', value: 'dept_name', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'PIC', value: 'pic_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DATE', value: 'dt_pr', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'QTY', value: 'qty', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WGT', value: 'wgt', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'STAT', value: 'stat', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'MEMO', value: 'memo_txt', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DT APRV 1', value: 'dt_approve1', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DT APRV 2', value: 'dt_approve2', align: 'left', class: 'primary--text blue lighten-5' },
                { text: '', value: 'showDetails', align: 'left', class: 'primary--text blue lighten-5' }
            ],
            itemLists: [],
            searchItem: '',
            loadingDatatable: false,
            dialogDetail: false,
            detailHeaders: [
                { text: 'NO.', value: 'pr_item', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'PROD. CODE', value: 'prod_code', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DESC', value: 'item_desc', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'QTY.', value: 'qty', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'WGT.', value: 'wgt', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'UNIT MEAS.', value: 'unit_meas', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'REMARK', value: 'remark', align: 'left', class: 'primary--text blue lighten-5' }
            ],
            detailItemLists: [],
            detaillLoadingDatatable: false,
            approveUser: false
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){
            
            this.$store.dispatch('setOverlay', true)
            $(".cardResult").hide()

            this.dateStartModel = this.currentDate()
            this.dateEndModel = this.currentDate()

            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/PurchaseRequest`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.millLists = res.data.mill
                this.departmentLists = res.data.department
                this.approveUser = res.data.approveUser
                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                console.log(err)

            })

        },

        async getData(){

            $(".cardResult").show()
            this.$store.dispatch('setOverlay', true)
            this.loadingDatatable = true
            this.itemLists = []

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/PurchaseRequest/getData`, { 

                    mill: this.millModel ? this.millModel : '',
                    department: this.departmentModel ? this.departmentModel : '',
                    dateFrom: this.dateStartFormatted,
                    dateTo: this.dateEndFormatted,
                    approved: this.approvedModel ? this.approvedModel : '',
                    stat: this.statModel ? this.statModel : ''
            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                if(res.data.result.length != 0){
                    $(".cardResult").show()
                    this.itemLists = res.data.result
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    $(".cardResult").hide()
                    Swal.fire({
                        icon: 'warning',
                        text: 'Data not found !'
                    })
                }

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err)

            })

        },

        async showItemDetails(item){

            this.detailItemLists = []
            this.dialogDetail = true
            this.detaillLoadingDatatable = true

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/PurchaseRequest/getDetails`, { 

                    pr_id: item.pr_id
            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                this.detailItemLists = res.data.result
                this.detaillLoadingDatatable = false

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err)

            })

        },

        async acceptApproval(item){

            Swal.fire({
                title: 'Accept this Purchase Request ?',
                text: item.memo_txt,
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'No',
            }).then((result) => {
            
                if (result.isConfirmed) {
                    
                    axios.post(`${process.env.VUE_APP_URL}/api/gbrk/PurchaseRequest/acceptPurchaseRequest`, { 

                        pr_id: item.pr_id
                    },
                    {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                    })
                    .then(res => {

                        if(res.data.result == 'success'){

                            Swal.fire({
                                icon: 'success',
                                text: 'Purchase request accepted succesfully '
                            })

                            this.getData()

                        }

                    })
                    .catch(err => {

                        console.log(err)

                    })
                    
                }

            })

        },

        async rejectApproval(item){

            Swal.fire({
                title: 'Accept this Purchase Request ?',
                text: item.memo_txt,
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'No',
            }).then((result) => {
            
                if (result.isConfirmed) {
                    
                    axios.post(`${process.env.VUE_APP_URL}/api/gbrk/PurchaseRequest/rejectPurchaseRequest`, { 

                        pr_id: item.pr_id
                    },
                    {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                    })
                    .then(res => {

                        if(res.data.result == 'success'){

                            Swal.fire({
                                icon: 'success',
                                text: 'Purchase request rejected succesfully '
                            })

                            this.getData()

                        }

                    })
                    .catch(err => {

                        console.log(err)

                    })
                    
                }

            })

        },

        currentDate() {
            // const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            // return date;

            var MyDate = new Date();
            var MyDateString;

            MyDate.setDate(MyDate.getDate());
            MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2);

            return MyDateString;
        }

    },
    watch: {

        
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

</style>